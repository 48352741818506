<template lang="html">
  <v-container fluid>
    <v-row>
      <v-col>
        <base-material-card icon="mdi-account-group">
          <template v-slot:heading>
            <v-toolbar color="transparent" flat>
              <v-toolbar-title> Daftar Target Kerja Bawahan</v-toolbar-title>
            </v-toolbar>
          </template>

          <v-row>
            <v-col class="d-flex justify-center">
              <div
                style="max-width:350px"
              >
                <v-list-item three-line>
                  <v-list-item-content>
                    <v-list-item-title class="headline mb-1">
                      Testo Terra Long Name So Long I Will Find You
                    </v-list-item-title>
                    <div class="mb-1">
                      Analisis Kinerja One Two Three Four Five
                    </div>
                    <v-list-item-subtitle>NIP. 1234567890</v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-avatar
                    size="100"
                    color="grey"
                  >
                    <img src="https://picsum.photos/id/1027/200" alt="">
                  </v-list-item-avatar>
                </v-list-item>
              </div>
            </v-col>
          </v-row>
          <crud
            :crudSettings="crudSettings"
            :headers="headers"
            :responseData="{data:items}"
            :detailData="detailData"
            :forceUpdateTable="forceUpdate"
            @onTableChange="updateTable"
          >
            <template v-slot:append-actions>
              <v-icon class="mr-1" title="validasi" @click="validasi()">
                mdi-checkbox-marked
              </v-icon>
            </template>
          </crud>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import _ from 'lodash'
import Crud from '@/components/common/Crud'

export default {
  components:{
    Crud,
  },

  data(){
    return{
      forceUpdate:false,
      loading:false,
      detailData:{},

      headers:[
        {text:'No', value:'no', sortable:false, class:'header-index-profile'},
        {text:'Target Kerja', value:'target_kerja', sortable:false, class:'header-index-profile'},
        {text:'Waktu', value:'waktu', sortable:false, class:'header-index-profile'},
        {text:'Tanggal Mulai', value:'tgl_mulai', sortable:false, class:'header-index-profile'},
        {text:'Tanggal Selesai', value:'tgl_selesai', sortable:false, class:'header-index-profile'},
        {text:'Status', value:'status', sortable:false, class:'header-index-profile'},
        {value:'aksi', sortable:false, class:'header-index-profile'},
      ],
      items:[{
        no:1,
        target_kerja:'a',
        waktu:'a',
        tgl_mulai:'a',
        tgl_selesai:'a',
        status:'a',
      }],

      dialogReset:false,
      crudSettings:{
        title:'',
        detailTitle:'Detail',
        enableDetail:false,
        enableCreate:true,
      },
    }
  },

  watch:{},

  created(){
    let val = {itemsPerPage:10, page: 1}
    this.updateTable(val)
  },

  computed:{},

  methods:{
    getImg(nip,ext){
      if (nip){
        let avatarImgUrl = process.env.VUE_APP_IMG_URL + nip +"."+ext
        // this.avatarImages[nip] = avatarImgUrl
        return avatarImgUrl
      }
      return ''
    },

    updateTable(val){},

    validasi(){},
  },
}
</script>

<style lang="css" scoped>
</style>
